<template>
  <div>
    <!--   <vx-card ref="filterCard" title="Filters" class="user-list-filters mb-8" :collapse-action="true">-->
    <!--     <div class="vx-row">-->
    <!--       <div class="vx-col md:w-1/4 sm:w-1/2 w-full">-->
    <!--         <label class="text-sm opacity-100 text-bold">Name</label>-->

    <!--         <vs-input type="search" v-model="searchTerm"  placeholder="Search.."  class="mb-4 md:mb-0 w-full"/>-->
    <!--       </div>-->
    <!--     </div>-->

    <!--     <div class="vx-row mt-5">-->
    <!--       <div class="vx-col w-full ml-auto">-->
    <!--         <vs-button class="float-right mr-4" size="small"-->
    <!--                    color="danger" type="border" icon-pack="feather" >CLEAR</vs-button>-->
    <!--         <vs-button class="float-right mr-4" size="small" @click="onClickSearch"-->
    <!--                    color="success" type="border" icon-pack="feather" >SEARCH</vs-button>-->
    <!--       </div>-->
    <!--     </div>-->
    <!--   </vx-card>-->
    <div class="vx-row">
      <!-- CARD 9: DISPATCHED ORDERS -->
      <div class="vx-col w-full">
        <vx-card title="Classifiers">
          <div slot="actions">
            <div class="flex flex-centered" v-if="hasTranslatableActions">
              <!--             <v-select class="mr-4" v-model="lan" :reduce="option => option" :options="current_user_language_array" :dir="$vs.rtl ? 'rtl' : 'ltr'" />-->
              <div class="d-flex mt-2">
                <!-- <vs-button
                  @click="changeLan('EST')"
                  class="mr-4"
                  v-bind:class="{ 'button-highlighted': lan === 'EST' }"
                  size="small"
                  color="success"
                  type="border"
                  icon-pack="feather"
                  >EST</vs-button
                > -->
                <vs-button
                  :key="lan_arr"
                  v-for="lan_arr in current_user_language_array"
                  @click="changeLan(lan_arr)"
                  v-bind:class="{ 'button-highlighted': lan === lan_arr }"
                  class="mr-4"
                  size="small"
                  color="success"
                  type="border"
                  icon-pack="feather"
                >
                  {{ lan_arr }}
                </vs-button>
              </div>
            </div>
            <!--          <vs-button @click="onClickCreateOrders" class color="primary" type="filled">Create Order</vs-button>-->
          </div>
          <template lang="html">
            <div>
              <vs-table
                search
                max-items="10"
                pagination
                :data="classifiers"
                stripe
                class="table-dark-inverted table-auto vs-con-loading__container"
              >
                <template slot="thead">
                  <!--               <vs-th sort-key="HsType">HS Type</vs-th>-->
                  <vs-th>Name (EST)</vs-th>
                  <vs-th v-if="lan !== 'EST'">Name ({{ lan }})</vs-th>
                  <vs-th>Action</vs-th>
                </template>

                <template slot-scope="{ data }">
                  <vs-tr
                    style="cursor: pointer"
                    @click.native="onClickClassifierView(data[indextr])"
                    :key="indextr"
                    v-for="(tr, indextr) in data"
                  >
                    <!--                 <vs-td :data="data[indextr].Id">{{ data[indextr].HsType }}</vs-td>-->
                    <vs-td :data="data[indextr].Code">
                      {{
                        getTranslation({
                          lang: 'EST',
                          value: data[indextr].Name,
                        })
                      }}
                    </vs-td>
                    <vs-td v-if="lan !== 'EST'" :data="data[indextr].Code">
                      {{ tableTranslations(data[indextr].Name) }}
                    </vs-td>
                    <!-- <vs-td :data="data[indextr].Id">
                      {{ tableTranslations(data[indextr].Name) }}
                    </vs-td> -->
                    <vs-td>
                      <div class="vx-row">
                        <!--                     <vx-tooltip class="mr-2" text="Edit" position="left">-->
                        <!--                       &lt;!&ndash;                    <vs-button @click="popupActive=true"&ndash;&gt;-->
                        <!--                       <vs-button-->
                        <!--                         v-if="data[indextr].Editable"-->
                        <!--                         radius-->
                        <!--                         color="primary"-->
                        <!--                         type="border"-->
                        <!--                         icon-pack="feather"-->
                        <!--                         icon="icon-edit"-->
                        <!--                       ></vs-button>-->
                        <!--                     </vx-tooltip>-->
                        <vx-tooltip text="View" position="right">
                          <!--                    <vs-button @click="popupActive=true"-->
                          <vs-button
                            @click="onClickClassifierView(data[indextr])"
                            radius
                            color="primary"
                            type="border"
                            icon-pack="feather"
                            icon="icon-eye"
                          ></vs-button>
                        </vx-tooltip>
                      </div>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </template>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from '@/repository/RepositoryFactory';
const ClassifierRepository = RepositoryFactory.get('classifier');
import store from '@/store/store';

export default {
  name: 'ClassifierTypes',
  data() {
    return {
      classifiers: [],
      searchTerm: 'storagetemperature',
      lan: store.getters.currentLanguage || 'EST',
      current_user_language_array: store.getters.current_user_language_array,
    };
  },
  async mounted() {
    await this.getClassifierTypes();
  },
  computed: {
    hasTranslatableActions() {
      return (
        this.classifiers &&
        this.classifiers[0] &&
        this.classifiers[0].Translatable
      );
    },
  },
  methods: {
    async getClassifierTypes() {
      this.$vs.loading();
      this.classifiers = (await ClassifierRepository.get()).data;
      this.$vs.loading.close();
    },
    onClickClassifierView(classifierType) {
      console.log(classifierType);
      // const name = classifierType.Name.find((n) => {
      //   return n.Language === 'ENG'
      // }).Value
      const HsType = classifierType.HsType;
      // this.$router.push({path: `/classifiers/${name.replace(/\s/g, '')}`})
      this.$router.push({ path: `/classifiers/${HsType}` });
    },
    async changeLan(lan) {
      this.lan = lan;
    },
    getTranslation({ lang, value, fallbackLang = 'EST' }) {
      if (!value) {
        return '';
      }
      const resultWithFallback = value.filter((n) => {
        return n.Language === lang || n.Language === fallbackLang;
      });

      return resultWithFallback.length === 2
        ? resultWithFallback.find((n) => n.Language === lang).Value
        : resultWithFallback[0].Value;
    },
    tableTranslations(Name) {
      return this.getTranslation({
        lang: this.lan,
        value: Name,
      });
    },
    // tableTranslations(Name) {
    //   if (!Name) {
    //     return '';
    //   }
    //   const name = Name.find((n) => {
    //     return n.Language === this.lan;
    //   });
    //   if (!!name) {
    //     return name.Value;
    //   } else {
    //     return Name.find((n) => {
    //       return n.Language === 'EST';
    //     }).Value;
    //   }
    // },
  },
};
</script>

<style scoped>
.button-highlighted {
  background: #64c272 !important;
  color: white !important;
}
</style>
